import * as React from "react"
import Layout from '../component/layout'
import Image from '../component/image'
import JourneyOmnidya from '../component/journeyOmnidya'
import Link from '../component/link'
import PageTitle from "../component/typography/pageTitle"
import SubTitle from "../component/typography/subtitle"
import Title from "../component/typography/title"

const BlogDetails = () => {
  return(
    <Layout blog page="blog-details-bg" title="Cybersecurity in the Insurance Industry and Beyond" captions="12.09.2020">
      <div className="container-xxl">
        <div className="row justify-content-center py-5">
          <div className="col-xs col-lg-6 mb-5">
            <Link to="/blog/" className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg> Back to Blog</Link>
          </div>
          <div className="w-100"></div>
          <div className="col-xs col-lg-6">
            <PageTitle title="Accounts and membership" className="pb-3" />
            <p>
              You must be at least 18 years of age to use this Website. By using this Website and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services.
            </p> 
            <p>
              Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services.
            </p>
          </div>
        </div>
        <div className="row justify-content-center pb-3">
          <div className="col-xs col-lg-8">
            <div className="row pb-5">
              <div className="col-sm col-md-5 col-lg-4 pb-3">
                <Image src="blog-details.png" className="img-fluid rounded-xlg" />
              </div>
              <div className="col">
                <SubTitle title="Acceptance of these terms" className="mb-3" />
                <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement.</p>
                <p>
                  You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide 
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center pb-3">
          <div className="col-xs col-lg-6">
            <Title title="Acceptance of these terms" className="pb-3" />
            <p>
              You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.
            </p> 
            <p>
              greement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services.
            </p>
            <Title title="Contacting us" className="py-3" />
            <p>
              If you have any questions about this Agreement, please contact us at support@remote.com.
            </p> 
            <p>
              This document was last updated on July 5, 2019
            </p>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-auto col-lg-8">
            <div className="py-5">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                <div className="mb-3">
                  <div className="font-weight-bold mb-2">Tags:</div>
                  <span className="tag tag-pill tag-primary">
                    Self Driving Car  
                    <Link to="#" className="closed">&times;</Link>
                  </span>
                  <span className="tag tag-pill tag-primary">
                    Al 
                    <Link to="#" className="closed">&times;</Link>
                  </span>
                  <span className="tag tag-pill tag-primary">
                    Cars
                    <Link to="#" className="closed">&times;</Link>
                  </span>
                </div>
                <div className="flex-shrink-0 mb-3" >
                  <div className="font-weight-bold mb-2">Share article via:</div>
                  <div className="socialMedia">
                    <Link to="#"><Image src="linkedin.svg" className="mb-2 mr-2" /></Link>
                    <Link to="#"><Image src="instagram.svg" className="mb-2 mr-2" /></Link>
                    <Link to="#"><Image src="facebook.svg" className="mb-2 mr-2" /></Link>
                    <Link to="#"><Image src="magnum.svg" className="mb-2 mr-2" /></Link>
                    <Link to="#"><Image src="twitter.svg" className="mb-2" /></Link>
                  </div>
                </div>
              </div>
            </div>
            <PageTitle title="Other Interesting Articles" className="text-center pb-5" />
            <div className="row pb-5">
              <div className="col-sm col-md-5 col-lg-4 pb-3">
                <Image src="blog-3.png" className="img-fluid rounded-xlg" />
              </div>
              <div className="col">
                <SubTitle title="Cybersecurity in the Insurance Industry and Beyond" className="mb-2" />
                <p>12.09.2020</p>
                <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ.</p>
                <Link to="#" className="btn btn-blue">Read More</Link>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-sm col-md-5 col-lg-4 pb-3">
                <Image src="blog-4.png" className="img-fluid rounded-xlg" />
              </div>
              <div className="col">
                <SubTitle title="Cybersecurity in the Insurance Industry and Beyond" className="mb-2" />
                <p>12.09.2020</p>
                <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ.</p>
                <Link to="#" className="btn btn-blue">Read More</Link>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-sm col-md-5 col-lg-4 pb-3">
                <Image src="blog-2.png" className="img-fluid rounded-xlg" />
              </div>
              <div className="col">
                <SubTitle title="Cybersecurity in the Insurance Industry and Beyond" className="mb-2" />
                <p>12.09.2020</p>
                <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ.</p>
                <Link to="#" className="btn btn-blue">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JourneyOmnidya gutter />
    </Layout>
  )
}

export default BlogDetails